import { useState } from "react";
import { useLocation } from "react-router-dom";

const BASE_PATH = 'https://generate.gov-om.co/saved/'

function PDFViewDefault(){
    const [toggled, setToggle] = useState(true);
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const file = searchParams.get('qr') || 'PC';

    return <div className="pdf-view-container">
        <div className={'accordian-control '+ (toggled? 'active': '')} onClick={() => setToggle(!toggled)}> <img src="/assets/tableOpen.png"/> <span>Verify Scanned QR Code Print</span></div>
        <iframe src={BASE_PATH+file+'.pdf'} className="pdf-viewer"/>
    </div>;
}

function PDFViewDefaultArab(){
    const [toggled, setToggle] = useState(true);
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const file = searchParams.get('qr') || 'PC';

    return <div className="pdf-view-container ar">
        <div className={'accordian-control '+ (toggled? 'active': '')} onClick={() => setToggle(!toggled)}> <img src="/assets/tableOpen.png"/> <span>تحقق من طباعة رمز الاستجابة السريعة الممسوحة</span></div>
        <iframe src={BASE_PATH+file+'.pdf'} className="pdf-viewer"/>
    </div>;
}

export default {PDFViewDefault, PDFViewDefaultArab};