function Header({onChange}){
    return <div className="header-container">
        <div className="logo container"><img src="/assets/esw-header-banner.png"/></div>
        <div className="container nav-bar">
         <div className="page-title">eCustoms Services Portal</div>
         <div className="set-lang">
           <div className="btn active" onClick={() => onChange('en')}>English</div>
           <div className="btn arab" onClick={() => onChange('ar')}>العربية</div>
        </div>
      </div>
    </div>
}

function HeaderArab({onChange}){
    return <div className="header-container ar">
        <div className="logo"><img src="/assets/esw-header-banner_ar.jpg"/></div>
        <div className="container nav-bar">
         <div className="page-title ar">بوابة خدمات الجمارك الإلكترونية</div>
         <div className="set-lang ar">
           <div className="btn arab active" onClick={() => onChange('ar')}>العربية</div>
            <div className="btn " onClick={() => onChange('en')}>English</div>
        </div>
      </div>
    </div>
}

export default {Header, HeaderArab};